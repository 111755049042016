<template>
  <v-container fluid>
    <v-layout>
      <h1>Access denied</h1>
        <button @click="signOutOidc">Sign out</button>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AccessDenied',
  methods: {
    ...mapActions([
      'signOutOidc'
    ])
  }
}
</script>
